import React from "react";

import Layout from "../../../components/Layout/Layout";

const ArticleCovid = () => {
  return (
    <Layout>
      <div className="article">
        <div className="article__ribbon">
          <div className="grid-container">
            <h1 className="title">Information related to COVID-19  </h1>
            <p>
              Look at you, look at me: containment of the COVID-19 virus, the
              protection of employees, customers and partners, but also the full
              maintenance of our services have top priority. S&T Mold therefore
              decided very early and with foresight to take appropriate
              measures.{" "}
            </p>
            <p>
              Below we have summarized important information for our customers
              and partners with regard to the measures to maintain delivery
              capability and the protection of employees at S&T, which we - of
              course also continuously update in accordance with all regulations
              and recommendations of the Moldovan Government.
            </p>
            <p>
              Your contact person will of course be happy to answer any
              questions you may have. You can also contact the following e-mail
              address: snt@snt.md 
            </p>
            <p className="bold">EMERGENCY PLAN / BUSINESS OPERATION </p>
            <p>
              In the sense of "Business Continuity Management" in connection
              with COVID-19, S&T Mold has developed a multi-level emergency plan
              and parts of it were already implemented in KW 10. The measures
              included focus in particular on minimizing the risk of a possible
              spread of the virus and the risk of resource bottlenecks due to
              illnesses or quarantine measures within S&T{" "}
            </p>
            <p>Preventive measures already implemented include: </p>
            <p className="bullet"> Increased hygiene </p>
            <p className="bullet">
              Reduction of social contacts by canceling larger meetings,
              increased holding of Skype / Team conferences instead of
              face-to-face meetings{" "}
            </p>
            <p className="bullet">
              Instruction to use largely established home office offerings at
              S&T{" "}
            </p>
            <p className="bullet">
              Central control of the measures by a crisis team at board level{" "}
            </p>
            <p className="bullet">
              Appointment of coordinators at all locations who are involved in
              all relevant decisions and ensure a seamless information chain or
              flexible coordination of measures.
            </p>
            <p>
              S&T Mold is also prepared to take additional measures immediately,
              as required or as required, such as the mandatory instruction to
              perform all "remote" tasks from the home office.
            </p>
            <h1 className="title"> Avalability of Goods </h1>
            <p>
              Almost all products contain components that are produced or
              assembled in affected regions. The supply situation has been tense
              since the situation in China escalated at the beginning of the
              year. Many manufacturers have started production again, but not to
              the extent necessary. There is currently no sign of an improvement
              in the current delivery capability or delivery times, and
              information about the current availability situation and delivery
              dates is constantly changing. Many companies are currently buying
              additional mobile devices to enable employees to work in their
              home office, which will further exacerbate the availability
              situation.
            </p>
            <p>
              In order to be able to cover your hardware requirements as well as
              possible, we ask you to determine an individually coordinated
              procedure with your contact person at S&T as soon as possible
              (e.g. binding forecast, extended stocking with acceptance
              guarantee, etc.), provided that this has not already happened.{" "}
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ArticleCovid;
